import { createAction, props } from '@ngrx/store';
import { AdminMap } from './admin.model';
import { Scheme } from '../scheme/scheme.model';
import { Map } from '../map/map.model';

export enum AdminActionType {
  GET_ALL_ADMIN_MAPS = '[Admin] Get All Admin Maps',
  GET_ALL_ADMIN_MAPS_SUCCESS = '[Admin] Get All Admin Maps :: Success',
  UPDATE_ADMIN_CURRENT_SCHEME = '[Admin] Update Admin Current Scheme',
  UPDATE_ADMIN_CURRENT_MAP = '[Admin] Update Admin Current Company Map',
}

export const GetAllAdminMapsAction = createAction(AdminActionType.GET_ALL_ADMIN_MAPS);
export const GetAllAdminMapsSuccessAction = createAction(
  AdminActionType.GET_ALL_ADMIN_MAPS_SUCCESS,
  props<{ adminMaps: AdminMap[] }>()
);
export const UpdateAdminCurrentSchemeAction = createAction(
  AdminActionType.UPDATE_ADMIN_CURRENT_SCHEME,
  props<{
    scheme: Scheme;
  }>()
);
export const UpdateAdminCurrentMapAction = createAction(
  AdminActionType.UPDATE_ADMIN_CURRENT_MAP,
  props<{
    map: Map;
  }>()
);
